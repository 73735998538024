import React from "react";

const FullScreenImage = ({ image, onClose }) => {
  return (
    <div
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        zIndex: 1000,
      }}
    >
      {
        <img
          onClick={(e) => e.stopPropagation()}
          src={image}
          style={{
            maxWidth: "80%",
            maxHeight: "80%",
          }}
        />
      }
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          backgroundColor: "white",
          border: "none",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        &times;
      </button>
    </div>
  );
};

export default FullScreenImage;

import React, { useState, useEffect } from "react";
import paperclip from "../image/paperclip.png";
import PhotoModal from "./PhotoModal";
import FullScreenImage from "./FullScreenImage";
import { Carousel } from "react-responsive-carousel";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CarouselImage from "./CaroselImage";
import "../App.css";

export const TaskListItem = ({
  imageClicked,
  task,
  onIncrement,
  onDecrement,
  handleClick,
  setIsModalOpen,
  isModalOpen,
}) => {
  const [taskDone, setTaskDone] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [picturePaths, setPicturePaths] = useState([]);
  const linkToPicture = "cloudinarypicturepath";
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [styleForTask, setStyleForTask] = useState("");
  const [styleForChecked, setStyleForCkeceked] = useState("");
  const [disableTask, setDisableTask] = useState("");
  const [hidden, setHidden] = useState(null);
  const [openModal, setOpenModal] = useState(true);

  const handleDragStart = (e) => e.preventDefault();

  useEffect(() => {
    // console.log("task", task);
    if (task.fgcompleted) {
      setStyleForCkeceked("text-gray-400");
      setStyleForTask("line-through text-gray-400 pointer-events-none");
      setDisableTask("pointer-events-none");
    }

    if (task.fgcompleted == 0 && task.fgnotifiedascomplete == 1) {
      setStyleForCkeceked("text-green-500");
      setDisableTask("pointer-events-none");
    }

    for (let index = 1; index <= 20; index++) {
      const picturePath = task[`${linkToPicture}${index}`];
      if (picturePath && picturePath !== "") {
        setPicturePaths((prevPaths) => [...prevPaths, picturePath]);
      }
    }
    console.log(picturePaths);
  }, [task, task.fgcompleted]);

  const onOpen = () => {
    setOpenModal(true);
    setOpenImage(true);
  };

  const onScale = (image) => {
    setFullScreenImage(image.target.src);
  };

  const closeFullScreenImage = () => {
    setFullScreenImage(null);
  };


  //Sets the actions when a task is ticked. We should be checking its state to see if its selected or not and do the opposite, rather than checking the
  const onSubmit = () => {
     handleClick(task);
  };

  const onHidden = () => {
    setOpenModal(false);
  };

  //not sure if regex is working on mobiles.
  // function BulletPointNumbers({ inputString }) {
  // // Split the string using positive lookbehind and positive lookahead
  // const segments = inputString.split(/(?<=\d+\.\s)|(?=\d+\.\s)/g).filter(Boolean);

  // // Merge segments to combine each number with its corresponding text
  // const mergedSegments = [];
  // for (let i = 0; i < segments.length; i += 2) {
  //   mergedSegments.push(segments[i] + (segments[i + 1] || ''));
  // }
  
  //   return (
  //     <ul className="list-disc pl-5">
  //       {mergedSegments.map((segment, index) => (
  //         <li key={index} className="mb-1">
  //           {segment.trim()}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // }

  function splitString(inputString) {
    const pattern = /\d+\.\s/g;
    let match;
    let prevIndex = 0;
    const segments = [];
  
    while ((match = pattern.exec(inputString)) !== null) {
      const matchedNumber = match[0];
      const currentIndex = match.index;
  
      // If it's not the first match, push the segment from the previous index to the current index
      if (currentIndex !== prevIndex) {
        segments.push(inputString.slice(prevIndex, currentIndex));
      }
  
      prevIndex = currentIndex + matchedNumber.length;
    }
  
    // Push the remaining part of the string
    if (prevIndex < inputString.length) {
      segments.push(inputString.slice(prevIndex));
    }
  
    return segments;
  }

  function BulletPointNumbers({ inputString }) {
    const segments = splitString(inputString);
  
    return (
      <ul className="list-disc pl-5">
        {segments.map((segment, index) => (
          <li key={index} className="mb-1">
            {segment.trim()}
          </li>
        ))}
      </ul>
    );
  }  
  
  
  
  
  

  return (
    <li className="hover:bg-gray-100 px-2 cursor-pointer pt-2 pb-2 border border-slate-200">
      <label
        className={`items-center mt-1 justify-between rounded cursor-pointer hover:bg-gray-100`}
        htmlFor="task_1"
      >
       
        <div className={`flex mx-auto ${disableTask}`}>
          <div className="flex-none items-center mr-3 justify-center w-5 h-5 text-transparent border-2 border-gray-300 rounded-full">
            <svg
              onClick={onSubmit}
              className={`w-4 h-4  fill-current ${task.isSelected ? 'text-green-500' : ''} ${styleForChecked}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z "
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="flex-grow flex-shrink">
            <div className="">
              <div className="rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 inline-block mr-1 whitespace-nowrap"># {task.itemno}</div>
              {(task.reference!="") ? (<div class="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 inline-block mr-1">{task.reference}</div> ) : ""}
              {(task.fgcriticalpath==1) ? (<div class="rounded-md bg-conceptOrange px-2 py-1 text-xs font-bold text-white ring-1 ring-inset ring-green-600/20 inline-block mr-1">Critical Path</div> ) : ""}
              </div>
              
            
            <div className={`overflow-auto break-words max-w-xs ${styleForTask}`} style={{ userSelect: 'none' }}onClick={onSubmit}>
            <BulletPointNumbers inputString={task.note} />
            {/* {task.note} */}
            {(task.fgcompleted==0 && task.fgnotifiedascomplete==1)? ( 
              <p className="mt-1 text-xs leading-5 text-conceptOrange">
                  Notified by {task.notifiedascompletebyclientname} on {task.notifiedascompletedate}
                </p>
            ):""}
            </div>
          </div>
 
          <div className="flex-none w-12 pr-1">
            <span
              onClick={onOpen}
              className="flex ml-2 opacity-60 hover:opacity-100 pointer-events-auto"
            >
              {picturePaths.length ? (
                <img className="w-6 h-5" src={paperclip} alt="paperclip" />
              ) : (
                ""
              )}
              {picturePaths.length ? [...new Set(picturePaths)].length : ""}

              <div className=""></div>
            </span>
          </div>

        </div>

  

        {openImage && openModal ? (
          <div
            onClick={onHidden}
            // className={`absolute top-0 right-0 bg-black w-screen h-screen bg-opacity-30 flex items-center justify-center z-50 `}
            className={`fixed top-0 right-0 bg-black w-screen h-screen bg-opacity-30 flex items-center justify-center z-50`}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="flex bg-white  gap-x-1 relative "
            >
           
              <CarouselImage picturePath={[...new Set(picturePaths)]} closeModal={onHidden} />
            </div>
          </div>
        ) : (
          ""
        )}

      </label>
    </li>
  );
};

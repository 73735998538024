import React from "react";
import logo from "../assets/logo-1.webp";

const Header = () => {
  return (
    <header className="flex w-full bg-header h-16">
      {/* h-6p */}
      <div className="flex header-block ">
        <img
          src={logo}
          alt="logo"
          className="flex pt-1 pb-1 logo pl-10"
        />
      </div>
    </header>
  );
};

export default Header;

import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
// import UploadImagesToCloudinary from './api.js'
import { CPSetTaskDone,CPUploadClientImages } from '../api.js'

const UploadPhotoModal = ({ show, onClose, mode, stageId, tasks, onDataChange}) => {
  // console.log('loading UploadPhotoModal')
  const [inputNameValue, setInputNameValue] = useState('');
  const [inputPhoneValue,setInputPhoneValue] = useState('');
  const [inputNotesValue,setInputNotesValue] = useState('');

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(show);
  const [isCallingAPI,setIsCallingAPI] = useState(false);

  //sample tasks to be complete passed to UploadPhotoModal
  //  console.log('passed stageid = ',stageId);
  // console.log('passed tasks = ',tasks,' mode = ',mode);
  // console.log('uploadPhotoModal mode = ',mode,' stageId = ',stageId,' tasks = ',tasks);
  //mode = upload or tasks
 
  // tasks = [{
  //   "id":1
  // }
  // ]

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleChange = (event) => {
    setInputNameValue(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setInputPhoneValue(event.target.value);
  };

  const handleNoteChange = (event) => {
    setInputNotesValue(event.target.value);
  };

  const cancelButtonRef = useRef(null)

  const handleUploadClick = async () => {
    let kirbydata = [];

    //Test if selectfiles and name have inputs
    if(mode === 'upload'){
      if (selectedFiles === null || inputNameValue === '') {
        alert('no files selected and/or no name entered');
        return;
      }    
    
    // console.log('length of file array = ',selectedFiles.length);
    //upload images if we have any. 
    if(selectedFiles.length > 0){
      const data = new FormData();
      const dataForKirby = [];
  
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append('file', selectedFiles[i]);
        data.append("upload_preset", "kkwvxteq");
  
      // console.log(data);
      // console.log(inputNameValue);
  
      try {
        setIsCallingAPI(true);
        const response = await fetch('https://api.cloudinary.com/v1_1/dj61fsarq/image/upload', {
          method: 'POST',
          body: data
        });
        setIsCallingAPI(false);
        if (response.ok) {
          const jsonResponse = await response.json();
          // console.log(jsonResponse);
  
          //call api to save to Kirby
         // data":[
            //     {
            //         "stageID": 1002,
            //         "clientname":"Bob"
            //         "url":"https://www.google.com.au/"
          
          let oneItem = {
            "stageID": stageId,
            "clientname": inputNameValue,
            "url": jsonResponse.secure_url
          }
          kirbydata.push(oneItem);
        
  
        } else {
          console.error(`HTTP error: ${response.status}`);
        }
      } catch (error) {
        console.error('Failed to upload file', error);
      }
    }
    console.log('image data to pass to our Kirby api =',kirbydata);
    try{
      setIsCallingAPI(true);
      const jsonResponse = await CPUploadClientImages(kirbydata);
      
      // console.log(jsonResponse);
      setSelectedFiles(null);
      setInputNameValue('');
      setInputPhoneValue('');
      setInputNotesValue('');

      onDataChange();
      onClose(true);
      setIsCallingAPI(false);
      

    }catch(e){
      setIsCallingAPI(false);
      console.log('error in tasks = ',e)
    }

    }
  }

   
  // const kirbyResponse = await CPSetTaskDone()
  if(mode === 'tasks'){
    if (inputNameValue === '') {
      alert('No name entered, please enter your name');
      return;
    }
    if (inputPhoneValue === '') {
      alert('No phone number entered, please enter your phone number');
      return;
    }


    
    //upload images if we have any.
    if(selectedFiles !== null){

      if(selectedFiles.length > 0){
        const data = new FormData();
        const dataForKirby = [];
    
        for (let i = 0; i < selectedFiles.length; i++) {
          data.append('file', selectedFiles[i]);
          data.append("upload_preset", "kkwvxteq");
    
        // console.log(data);
        // console.log(inputNameValue);
    
        try {
          setIsCallingAPI(true);
          const response = await fetch('https://api.cloudinary.com/v1_1/dj61fsarq/image/upload', {
            method: 'POST',
            body: data
          });
          setIsCallingAPI(false);
          if (response.ok) {
            const jsonResponse = await response.json();
            // console.log(jsonResponse);
    
            //call api to save to Kirby
           // data":[
              //     {
              //         "stageID": 1002,
              //         "clientname":"Bob"
              //         "url":"https://www.google.com.au/"
            
            let oneItem = {
              "stageID": stageId,
              "clientname": inputNameValue,
              "url": jsonResponse.secure_url
            }
            kirbydata.push(oneItem);
          
    
          } else {
            console.error(`HTTP error: ${response.status}`);
          }
        } catch (error) {
          console.error('Failed to upload file', error);
        }
      }
      console.log('image data to pass to our Kirby api =',kirbydata);
      try{
        setIsCallingAPI(true);
        const jsonResponse = await CPUploadClientImages(kirbydata);
        
        // console.log(jsonResponse);
        setSelectedFiles(null);
        setInputNameValue('');
        setInputPhoneValue('');
        setInputNotesValue('');
        
        onDataChange();
        onClose(true);
        setIsCallingAPI(false);
        
  
      }catch(e){
        setIsCallingAPI(false);
        console.log('error in tasks = ',e)
      }
  
      }
    } 
   

    //if no images, just set task to done
    // console.log('we have our tasks here = ',tasks)
    const newtasksArray = tasks.map((taskid) => ({
      taskid,
      clientname: inputNameValue, 
      phone: inputPhoneValue,
      notes: inputNotesValue
    }));
  

    try{
      setIsCallingAPI(true);
      const jsonResponse = await CPSetTaskDone(newtasksArray);
      setSelectedFiles(null);
      setInputNameValue('');
      setInputPhoneValue('');
      setInputNotesValue('');
      
      setIsCallingAPI(false);
      onDataChange();
      onClose(true);

    }catch(e){
      setIsCallingAPI(false);
      console.log('error in tasks = ',e)
    }
    
  }
  
  // {onClose}
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          {/* <div className="flex d min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"> */}
          <div className="fixed inset-0 flex justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <h2 className="text-center text-lg font-bold tracking-tight text-black">{mode==="tasks" ? `Complete Tasks` : "Upload Images" } </h2>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 "> 
            <label htmlFor="name" className="block text-xs font-medium text-gray-900">
              Name
            </label>  
            <input
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={handleChange}
              value={inputNameValue}
            />
          </div>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 "> 
            <label htmlFor="name" className="block text-xs font-medium text-gray-900">
              Phone
            </label>  
            <input
              type="phone"
              name="phone"
              id="phone"
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={handlePhoneChange}
              value={inputPhoneValue}
            />
          </div>
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 "> 
          <label htmlFor="comment" className="block text-xs font-medium text-gray-900">
              Add a note
            </label>
              <textarea
                rows={3}
                name="comment"
                id="comment"
                onChange={handleNoteChange}
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={inputNotesValue}
              /> 
            </div>
    
<label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="multiple_files">Upload Photos</label>
<input className="block w-full text-sm  border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="multiple_files" type="file" multiple onChange={handleFileChange}/>

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    disabled={isCallingAPI}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleUploadClick}      
                  >
                    {isCallingAPI ? <svg className="animate-spin h-5 w-5 mr-3 bg-cyan-500" viewBox="0 0 24 24"></svg> : ""}
                    {mode==="tasks" ? `Complete ${tasks.length} Tasks` : "Upload Images" } 
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={onClose}
                    // ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
};

export default UploadPhotoModal;


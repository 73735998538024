// import Cookies from "js-cookie";

export function getURLs() {
  //  process.env.NODE_ENV == null ? process.env.NODE_ENV = 'development' : process.env.NODE_ENV;

  if (process.env.NODE_ENV === 'development') {
      return {
      ui: 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase',
      api: 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase',
      
      //NB: These are the sandbox urls (Source/Sandbox/docker-compose.yaml)
    //   auth: 'http://localhost:5560',
    //   api: 'http://localhost:5570/api'
      }
  }

  //Read cookie Config (set by nginx)
  //Config is base64-encoded json
//   const config = window.atob(Cookies.get('Config'));
//   return JSON.parse(config);
}

export function setGetRequestConfigs(token) {
  return {
    method: 'GET',
    headers: {
        'apikey':`${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json;charset=utf-8',
    }
  }
}

export function setPostRequestConfigs(token, body) {
  return {
    method: 'POST',
    headers: {
        'apikey':`${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body)
  }
}

export function setCloudinaryPostConfigs(token, body) {
    return {
      method: 'POST',
      headers: {
          'apikey':`${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json;charset=utf-8',
      },
      body: JSON.stringify(body)
    }
  }
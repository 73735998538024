import React from "react";
import { Carousel } from "react-carousel-minimal";
import styles from "./Caroselimage.css"; // import css module
import "../App.css";

const CarouselImage = ({ picturePath, closeModal }) => {
  const data = picturePath.map((item) => {
    console.log("item", item);
    return {
      image: item,
      caption: "",
    };
  });

  // const closeModal1 = () => {
  //   console.log('clicked close button, not passing closeModal function to parent')
  //   if (typeof closeModal === 'function') {
  //     closeModal();
  // } else {
  //     console.log('closeModal prop is not a function');
  // }

  // }

  const onSubmit = () => {

    closeModal(true)
    
 };

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };


  return (
    <div className="carusel-fixed relative">
      <div style={{ textAlign: "center" }}>

      {/* <img className="absolute top-0 right-0 w-6 h-5" src={paperclip} alt="paperclip" /> */}
      <div className="absolute top-0 right-0 w-6 h-5" onClick={onSubmit}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
      </div>
        
        <div className="pr-1">
          {/* style={{
            padding: "0 20px",
          }} */}
        {/* > */}
          
          <Carousel
            data={data}
            time={100}
            width="1000px"
            height="600px"
            captionStyle={captionStyle}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={false}
            dots={false}
            pauseIconColor="white"
            pauseIconSize="1000px"
            slideBackgroundColor="darkgrey"
            slideImageFit="contain"
            thumbnails={true}
            thumbnailWidth="80px"
            className={styles}
            style={{
              textAlign: "center",
              maxWidth: "900px",
              maxHeight: "1000px",
              minWidth: "250px",
              margin: "20px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default CarouselImage;

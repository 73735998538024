import React, { useState } from "react";

const PhotoModal = ({ setIsModalOpen, picturePaths }) => {
  return (
    <div
      onClick={() => setIsModalOpen(false)}
      className={`w-screen h-full flex fixed top-0 left-0 items-center justify-center bg-black bg-opacity-20 `}
      
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative w-1/3 h-96 bg-white"
      >
        <div
          className="flex self-start justify-end absolute cursor-pointer top-2 right-4 hover:text-gray-300/90"
          onClick={() => setIsModalOpen(false)}
        >
          x
        </div>
        <div className="pl-5 pt-3">Pictures</div>
        <div>
          {console.log("picturePath", picturePaths)}
        </div>
      </div>
    </div>
  );
};

export default PhotoModal;

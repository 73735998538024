import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useParams,
  useHistory
} from "react-router-dom";
import TaskList from "./TaskList";
import NotFound from "./NotFound";
import "./index.css";
import Header from "./components/header";
import { TasksContainer } from "./TasksContainer";
import {getCPProjectsForCustomerKey} from './api'

function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [projectType, setProjectType] = useState('');
  const { clientId } = useParams();

  console.log(clientId);
  const API_KEY = "ghsadgewq793lk;ds]23ERGFElpP";
  
  useEffect(() => {
    const fetchData = async () => {

      try {  
        const data = await getCPProjectsForCustomerKey(API_KEY,clientId);
        console.log('my data! = ',data);
    if (data){

        setProjects(data.response.Projects);
        setProjectType(data.response.ReturnedProjectsType);
       

    }

      } catch (error) {
        console.error(error);
      }
    }
    fetchData();

      
  }, [clientId]);

  
  function PageTitle(projectType) {
    let title;
  
    switch (projectType) {
      case 'client':
        title = 'Builder Projects';
        break;
      case 'pm':
        title = 'My Projects';
        break;
      case 'installer':
        title = 'Installer Projects';
        break;
      case 'service':
        title = 'Service & Final Projects';
        break;
      default:
        title = 'Builder Projects';
    }
  
    return (
      <h1 className="flex justify-center items-center text-2xl font-bold text-center h-6p py-14 ">
        {title}
      </h1>
    );
  }



  return (
    <div>
      <div className="mx-auto">
         <PageTitle userType={projectType} />
        {/* <h1 className="flex justify-center items-center text-2xl font-bold text-center h-6p py-14 ">
          {(projectType =="client" ? "Client Projects" : "My Projects" )}
          {/* <PageHeader userType={projectType} /> */}
        {/* </h1> */} 
        <ul
          className=" sm:grid-cols-2 md:grid-cols-1 bg-gray-100 w-full py-16
          ring-1 ring-gray-300 min-h-screen"
        >
          {projects.map((project, i) => (
            <li
              key={i}
              className="flex justify-between gap-x-6 py-5 first:rounded-t-2xl first:ring-1 first:ring-gray-200
                last:rounded-b-2xl last:ring-1 last:ring-gray-200 w-11/12 mx-auto bg-white shadow-gray-300 shadow-sm px-8
                items-start ring-1 ring-gray-200 h-min"
            >
              <div className="flex gap-x-4 ">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <div className="inline-flex m-1">
                    {project.projectname} 
                    </div>
                    {(projectType =="client" ? " " : 
                    <div className="inline-flex items-center gap-x-1.5 rounded-md m-1 px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                      <svg className="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx={3} cy={3} r={3} />
                      </svg>
                      {project.clientname}
                    </div>
                    )}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {/* todo */}
                  </p>
                </div>
              </div>

              <button
                className="flex bg-white-500 hover:bg-gray-50 text-black font-bold px-4 p-1 border
                    border-gray-700 rounded-2xl h-3/4 self-center max-sm:text-xs"
              >
                <Link
                  to={`/client/${clientId}/project/${project.customerplatformurlkey}}`}
                >
                  view project
                </Link>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <Header />
        <Routes>
          <Route path="/client/:clientId" element={<ProjectList />} />
          <Route
            path="/client/:clientId/project/:projectId"
            element={<TasksContainer />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import {
    setPostRequestConfigs,
    setCloudinaryPostConfigs,
    setGetRequestConfigs,
    getURLs
  } from './utils/fetchConfigs'


  const API_KEY = "ghsadgewq793lk;ds]23ERGFElpP";
//   const API = `https://conceptwindows.kirbyerp.com:9234/kirbydatabase/getCPProjectDetails?key=`;
//   const config = {
//     method: "GET",
//     headers: {
//       apikey: API_KEY,
//     },
//   };

export function getCPProjectsForCustomerKey(token, clientId){
  console.log('project ID IS ',clientId);
  const url = 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase/getCPProjectsForCustomerKey';
  return fetch(
      `${url}?key=${clientId}`,
      setGetRequestConfigs(token)
  ).then((resp) => resp.json())
}

  export function getCPProjectDetails(token, projectid){
    console.log('project ID IS ',projectid);
    const url = 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase/getCPProjectDetails';
    return fetch(
        `${url}?key=${projectid}`,
        setGetRequestConfigs(token)
    ).then((resp) => resp.json())
  }

  export function UploadImagesToCloudinary(token, files) {
    const url = 'https://api.cloudinary.com/v1_1/demo/image/upload';
    return fetch(
        `${url}`,
        setCloudinaryPostConfigs(token, {
            files
        })
    ).then((resp) => resp.json())
  }
  
// CPSetTaskDone
//   Once a user has completed a task, we will call this api to update the task as completed.
//   "tasks":[
//     {
//         "taskid": 5,
//         "clientname":"Bob"
//     },
//      {
//         "taskid": 6,
//         "clientname":"Ryan"
//     }
// ]
  export function CPSetTaskDone(tasks) {
    // const baseurl = getURLs().api;
    const url = 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase';
    return fetch(
        `${url}/CPSetTaskDone`, 
        setPostRequestConfigs(API_KEY, {
            tasks
                  })
                ).then((resp) => resp.json())
              }

            //CPUploadClientImages
            //   Once user has uploaded their images to cloudinary, we get there urls and upload them in this api call, along with the clients name and stageID.
            //   "data":[
            //     {
            //         "stageID": 1002,
            //         "clientname":"Bob"
            //         "url":"https://www.google.com.au/"
            //     },
            //      {
            //         "stageID": 1002,
            //         "clientname":"Ryan"
            //         "url":"https://honey.nine.com.au/latest/bunnings-warehouse-announces-huge-change-to-sausage-sizzle/ffcf9295-18b7-4404-98e5-287f8945c91a"
            //     }
            // ]

    export function CPUploadClientImages(data) {
    const url = 'https://conceptwindows.kirbyerp.com:9234/kirbydatabase';
    return fetch(
        `${url}/CPUploadClientImages`, 
        setPostRequestConfigs(API_KEY, {
          data
                    })
                ).then((resp) => resp.json())
                }
  
//   export function getUnscheduledJobsByLine(token, line) {
//     const url = getURLs().api;
//     return fetch(
//       `${url}/Schedule/Unscheduled/${line}`,
//       setGetRequestConfigs(token)
//     ).then((resp) => resp.json())
//   }
  
//   export function getChangedScheduledJobs(token, line, jobs) {
//     const url = getURLs().api;
//     return fetch(
//       `${url}/Schedule/Resolve/${line}`,
//       setPostRequestConfigs(token, {
//         jobs
//       })
//     ).then((resp) => resp.json())
//   }
  
//   export function updateScheduledJobs(token, line, jobs) {
//     const url = getURLs().api;
//     return fetch(
//       `${url}/Schedule/Update/${line}`,
//       setPostRequestConfigs(token, {
//         jobs
//       })
//     )
//   }
import React from "react";

export const TaskButtonConfirm = ({ clickCounter, onButtonClick, stageId }) => {

  return (
    <div className="pb-4">
      <button
        onClick = {() => onButtonClick('tasks',stageId)}
        type="button"
        className="font-large flex focus:outline-none text-white bg-green-500 ring-green-900 hover:bg-green-900 focus: focus:bg-green-500  rounded-lg text-sm px-12 py-3 mb-2 dark:bg-green-700 dark:hover:bg-green-800 dark:focus:bg-green-800 "
      >
            Confirm {clickCounter} {clickCounter === 1 ? "Task" : "Tasks"}
        <span className="">
          <svg
            className={`w-5 h-5 fill-current text-white-500 ml-1`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z "
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { MailIcon, PhoneIcon } from "@heroicons/react/solid";
// import axios from "axios";
import paperclip from "./image/paperclip.png";
import "./index.css";
import Header from "./components/header";
import { TaskListItem } from "./components/TaskListItem";
import Modal from "./components/PhotoModal";
import "./App.css";
import UploadPhotoModal  from "./components/UploadPhotoModal";

import "react-alice-carousel/lib/alice-carousel.css";
import CarouselImage from "./components/CaroselImage";



function TaskList({
  imageClicked,
  openPhotoModal,
  isPhotoModalOpen,
  closePhotoModal,
  onIncrement,
  onDecrement,
  clickCounter,
  stageName,
  tasksByStage,
  handleClick,
  completedTaskName,
  setIsModalOpen,
  isModalOpen,
  currentTasksStatus,
  onButtonClick
}) {

  const [openImage, setOpenImage] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [picturePaths, setPicturePaths] = useState([]);

  const onHidden = () => {
    setOpenModal(false);
  };

const onOpenPhotoModal = () => {
  console.log("IM HERE CLICKING PAPER CLIPS! currentTasksStatus.stageimages = ",currentTasksStatus.stageimages)
  
  // setIsModalOpen(true);
  // Flatten the array and extract the "url" property using map
  const flattenedUrls = currentTasksStatus.stageimages.map(imageData => imageData.url);
  setPicturePaths(flattenedUrls);

   setOpenModal(true);
   setOpenImage(true);
    console.log('current task',picturePaths)
   
  
};


// function clickUploadButton(){
//   setModalMode('upload')
//   openPhotoModal(true);
// }

  return !stageName || !currentTasksStatus ? (
    ""
  ) : (   

    <li
      className={`flex flex-col max-w-full p-4 bg-white rounded-lg shadow-lg ${
        imageClicked ? "background-down" : ""
      }
    `}
    >
      {openImage && openModal ? (
        <div
                  onClick={onHidden}
                  // className={`absolute top-0 right-0 bg-black w-screen h-screen bg-opacity-30 flex items-center justify-center z-50 bg-cover`}
                  className={`fixed top-0 right-0 bg-black w-screen h-screen bg-opacity-30 flex items-center justify-center z-50`}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex bg-white  gap-x-1 relative "
                  >
                    <CarouselImage picturePath={[...new Set(picturePaths)]}  closeModal={onHidden}/>
                  </div>
                  </div>
      ) : (
        ""
      )}
      <div className="flex items-center mb-6">
        <svg
          className="h-8 w-8 text-indigo-500 text-black stroke-current"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
          />
        </svg>
        <h4 className="font-semibold ml-3 text-lg">{stageName}</h4>
       
        <span
              className="flex ml-2 opacity-60 hover:opacity-100 "
            >
        {currentTasksStatus.stageimages.length ? (
               <img className="w-6 h-5" src={paperclip} alt="paperclip" onClick={onOpenPhotoModal} />
              ) : (
                ""
              )}          
        {currentTasksStatus.stageimages.length >= 1 ? [...new Set(currentTasksStatus.stageimages)].length : ""}
                </span>

      </div>
      <ul>
        {currentTasksStatus.tasks.map((task) => (
       
          <TaskListItem
            task={task}
            key={task.taskid}
            clickCounter={clickCounter}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            handleClick={handleClick}
            completedTaskName={completedTaskName}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        ))}
      </ul>
      {currentTasksStatus.isCritical == true ? 
      <div className="bg-conceptOrange mt-auto text-white text-sm p-2 text-center rounded-b-lg font-bold">
      Critical Path = Concept Windows are unable to determine final manufacture sizes, until such time the below items are completed and closed out.
      </div> : ""}
      {/* <button
        type="button"
        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 m-2 w-full"
        onClick = {() => onButtonClick('upload',currentTasksStatus.stageid)}
        >
        Upload Images
      </button>    */}
    </li>
  );

}

  
export default TaskList;

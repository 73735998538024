import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TaskList from "./TaskList";
import { TaskButtonConfirm } from "./components/TaskButtonConfirm";
import PhotoModal from "./components/PhotoModal";
import datadata from "./arr.json";
import paperclip from "./image/paperclip.png";
import IsLoading from "./components/IsLoading";
import {getCPProjectDetails} from './api'
import UploadPhotoModal  from "./components/UploadPhotoModal";

import "./App.css";

export const TasksContainer = () => {
  const [tasksByStage, setTasksByStage] = useState([]);
  const [clickCounter, setClickCounter] = useState(0);
  const [stages, setStages] = useState([]);
  const [completedTaskName, setCompletedTaskName] = useState([]);
  const [pictureArr, setPictureArr] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [plansAndDetails, setPlansAndDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageClicked, setImageCliked] = useState(false);

  //my one for managing all tasks status in app
  const [currentTasksStatus,setTasksStatus] = useState([]);
  const [tasksSelected, setSelectedTasks] = useState([]);
  const [modalMode, setModalMode] = useState(''); 
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [uploadPhotoStageId, setUploadPhotoStageId] = useState(null);
  const [dataDependency, setDataDependency] = useState('');

  const API_KEY = "ghsadgewq793lk;ds]23ERGFElpP";

  //Notification that can be customised with title and body
  const [showNotification, setNotification] = useState({})

  //photo modal
    const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);  
  
  //go back navigation
  // const navigate = useNavigate();
	// const goBack = () => {
	// 	navigate(-1);
	// }
  const goBack = () => {
    const clientUrl = window.location.href.split('/project')[0];
    window.location.href = clientUrl;
  }

  const closePhotoModal = () => {
    setPhotoModalOpen(false);
    console.log('closing..',isPhotoModalOpen);
  }

  const openPhotoModal = () => {
    setPhotoModalOpen(true);
    console.log('opening..',isPhotoModalOpen);
  }

  const handleDataChange = () => {
    // Update dataDependency when notified by the child component
    setDataDependency(Date.now().toString());
  };

 
  const handleClick = (item) => {

  //  console.log('item from handleClick =',item);
  //  console.log('need to look for task',item.taskid,' with stageid =',item.stage_id,' previous task object')
  //  console.log('current tasks object = ',currentTasksStatus);
  let alertBox = false;
   
   setTasksStatus((prevTasksByStage) => {
       
       const updatedTasksByStage = [];

       for (let stageId in prevTasksByStage) {
        
        const stage = prevTasksByStage[stageId];
  
          const updatedTasks = stage.tasks.map(task => {
            // If the task is being selected (i.e., it's currently not selected),
          // but the stageId doesn't match the selectedStageId, don't select it.
            if (!item.isSelected && selectedStageId !== null && item.stage_id !== selectedStageId) {
              // console.log('Cant select task from a different stage')
              // alert('You can only select tasks from the same stage')
              alertBox = true;
              return task;
            }

            if (task.taskid === item.taskid) { 
              setSelectedStageId(item.stage_id);
              // This is the clicked task. Flip the 'isSelected' value.
               // If the task is being selected, update the selectedStageId.
              return { ...task, isSelected: !item.isSelected };
            }

            // This is not the clicked task. Don't modify it.
            return task;
          });

            // Check if all tasks of this stage are deselected.
            const isAnyTaskSelected = updatedTasks.some(task => task.isSelected);

            // If no task of this stage is selected, reset the selectedStageId.
            // console.log('stageId =',stage.stageid, 'selectedStageId =',selectedStageId)
            if (stage.stageid === selectedStageId && !isAnyTaskSelected) {
              setSelectedStageId(null);
            }
          

        // Add the updated tasks to the updatedTasksByStage.
        updatedTasksByStage[stageId] = { ...stage, tasks: updatedTasks };
      }
      

         // Calculate the total number of tasks that are selected.
    let totalSelected = 0;
    let tasksSelected = [];
    for (let stageId in updatedTasksByStage) {
      const selectedTasks = updatedTasksByStage[stageId].tasks.filter(task => task.isSelected);
      totalSelected += selectedTasks.length;
      
      // Extract task IDs and add them to tasksSelected
      const selectedTaskIds = selectedTasks.map(task => task.taskid);
      tasksSelected = [...tasksSelected, ...selectedTaskIds];
      setSelectedTasks(tasksSelected);

    }

      setClickCounter(totalSelected);  // Set the new count.

      if(alertBox){
        alert('You can only select tasks from the same stage')
      }

      return updatedTasksByStage;
    });


  };



  
  const subject = "Confirming%20Tasks%20Complete%For%20Project%20";
  const body = encodeURIComponent(`Tasks Complete: \n${completedTaskName.join("\n")}`);
  const toMail = ` mailto:ryan.osullivan4@outlook.com?subject=${subject}&body=${body}`;

  const onIncrement = () => {
    setClickCounter(clickCounter + 1);
  };

  const onDecrement = () => {
    setClickCounter(clickCounter - 1);
  };

  const handleModalMode = (mode,stageid) => {
    setModalMode(mode);
    openPhotoModal();
    setUploadPhotoStageId(stageid);
    console.log('!!!!!handleModalMode clicked, stageid = ',stageid);
  };

 

// //old but working
//   const projectStages2 = stages.map((item, i) => {
//     const stageName = item.stagedesc;
//     const stageId = item.stageid;
//     const tasksByStageId = tasksByStage.filter(
//       (task) => task.stage_id === stageId
//     );
//     console.log(tasksByStageId);
//     console.log("generating TaskList component with name ", stageName);
//     return (
//       <TaskList
//         imageClicked={imageClicked}
//         openPhotoModal={openPhotoModal}
//         isPhotoModalOpen={isPhotoModalOpen}
//         tasksByStage={tasksByStageId}
//         onDecrement={onDecrement}
//         onIncrement={onIncrement}
//         stageName={stageName}
//         clickCounter={clickCounter}
//         handleClick={handleClick}
//         completedTaskName={completedTaskName}
//         setIsModalOpen={setIsModalOpen}
//         setPictureArr={setPictureArr}
//         isModalOpen={isModalOpen}
//         currentTasksStatus={currentTasksStatus}
//       />
//     );
//   });

  //Display Stage panel with tasks inside and upload button
  const projectStages = currentTasksStatus.map((item, i) => {
    
    console.log('inside projectStages2',item);
     const stageName = item.stagedesc;
     const stageId = item.stageid;
     const stageImages = item.stageimages;
    // const tasksByStageId = tasksByStage.filter(
    //   (task) => task.stage_id === stageId
    // );
    // console.log("generating TaskList component with name ", stageName);
    return (
      <TaskList
        key={item.stageid}
        imageClicked={imageClicked}
        openPhotoModal={openPhotoModal}
        isPhotoModalOpen={isPhotoModalOpen}
        closePhotoModal={closePhotoModal}
        setModalMode={setModalMode}
        // tasksByStage={tasksByStageId}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        stageName={stageName}
        clickCounter={clickCounter}
        handleClick={handleClick}
        completedTaskName={completedTaskName}
        setIsModalOpen={setIsModalOpen}
        setPictureArr={setPictureArr}
        isModalOpen={isModalOpen}
        currentTasksStatus={item}
        onButtonClick={handleModalMode}
      />
    );
  });



  //Download PDF section
  const plansAndDetailsMap = plansAndDetails.map((item, i) => {
    const url = item.cloudinarypath;
    const filename = url.substring(url.lastIndexOf("/") + 1);
    return (
      <div
        className={`flex justify-between p-4 ${
          i !== plansAndDetails.length - 1 ? "border-b" : ""
        }`}
        key={i}
      >
        <div className="flex justify-start items-center ">
          <img className="w-10 h-9 mr-4" src={paperclip} alt="paperclip" />
          <div>{item.filedescription}</div>
        </div>
        <div className="pl-4 flex items-center text-blue-800 hover:text-blue-500">
          {/* <a href={url} 
            target="_blank"
            rel="noreferrer">
            <button>View</button>
          </a> */}
           <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}>
            View
          </button>
        </div>
      </div>
    );
  });

  const { projectId } = useParams();

  useEffect(() => {
    
    setClickCounter(0);
    setSelectedStageId(null);

    const fetchData = async () => {
      try {  
        const data = await getCPProjectDetails(API_KEY,projectId);
        console.log('fetching data from getCPProjectDetails');

        // setTodos(data.response.Tasks);   
        
        
        setProjectName(data.response.ProjectName);

        //lets add isSelected=false to this. 
        if (data) {
          const tasks = data.response.Tasks;
          const stages = data.response.Stages;
          const stageImages = data.response.Images;
          // const isCritical = tasks
          //                   .filter(task => task.stage_id === stage.stageid)
          //                   .some(task => task.fgcriticalpath === 1);

      
          const organisedTasks = stages.map(stage => {
            return {
               stageid: stage.stageid,
               stagedesc: stage.stagedesc,
               stageimages:stageImages.filter(image => image.cpprojectstageid === stage.stageid),
               isCritical: tasks
               .filter(task => task.stage_id === stage.stageid)
               .some(task => task.fgcriticalpath === 1 && task.fgcompleted === 0 && task.fgnotifiedascomplete === 0),
              tasks: tasks
                 .filter(task => task.stage_id === stage.stageid)
                .map(task => ({ ...task, isSelected: false }))
            };
          });
          console.log('organised tasks = ',organisedTasks);

           setTasksStatus(organisedTasks);
        }

        setTasksByStage(data.response.Tasks);
        

        setStages(data.response.Stages);
        setPlansAndDetails(data.response.PlansAndDetails);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading();
      }
    };

    fetchData();
  }, [dataDependency]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center pt-10">
        <IsLoading />
      </div>
    );
  }
  return (
    <>
      <div
        className={`flex flex-col d w-full items-center md:min-h-screen   ${
          // currentTasksStatus.length === 1 ? "h-screen" : ""
          currentTasksStatus.length === 1 ? "min-h-screen" : ""
        }  bg-gray-100 font-medium pb-10 ${
          imageClicked ? "background-down" : ""
        }
        }`}
      >
        
        
        <div className=" text text-2xl text-center font-bold flex items-center justify-center bg-white w-full h-6p py-14  ring-1 ring-gray-300">
        <button 
          type="button"
          className="bg-gray-300 hover:bg-indigo-600 hover:text-white text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={goBack}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
          </svg>
      {/* <span>Back</span> */}
        </button>

          <div className="px-2">
          {" "}
          {projectName}
            </div>
       
        </div>
        <ul role="list"
          //new
          className="mx-auto px-6 mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          //previous
          // className={`flex text-gray-600 gap-4 p-2 pt-16 grid ${
          //   currentTasksStatus.length === 2 ? "md:grid-cols-2" : ""
          // } ${
          //   currentTasksStatus.length !== 1 && currentTasksStatus.length !== 2
          //     ? "grid-container"
          //     : "grid-cols-1"
          // } `}
        >
        {/* <button
        type="button"
        className="rounded w-48 bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={goBack}
      >
        Back
      </button> */}
          {projectStages}
        </ul>


        <div className="flex flex-col pt-10">
          {!clickCounter ? (
            ""
          ) : (
             <TaskButtonConfirm clickCounter={clickCounter} onButtonClick={handleModalMode} stageId={selectedStageId}/>
          )}
           <UploadPhotoModal show={isPhotoModalOpen} onClose={closePhotoModal} mode={modalMode} stageId={uploadPhotoStageId} tasks={tasksSelected} onDataChange={handleDataChange}/>
        </div>
        <div className="flex text-3xl flex-col pb-8 text-gray-700 max-sm:text-xl">
          Plans & Details
        </div>
        <div
          className={`rounded-md border bg-white w-3/5 lg:w-2/5 max-sm:w-10/12 ${
            imageClicked ? "background-down" : ""
          }`}
        >
          {plansAndDetailsMap}
        </div>
      </div>
    </>
  );
};
